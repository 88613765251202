import React from "react";

import Page from "src/components/Page";

import Home from "src/pages/Index/sections/home";
import About from "src/pages/Index/sections/about";
import Plan from "src/pages/Index/sections/plan";
import Platform from "src/pages/Index/sections/platform";
import Contact from "src/pages/Index/sections/contact";
import Sponsors from "src/pages/Index/sections/sponsors";

import "./Index.scss";

const Index: React.FC = () => {
    return (
        <Page className="index" id="index">
            <Home />
            <About />
            {/*<Platform />*/}
            <Plan />
            <Contact />
            <Sponsors />
        </Page>
    );
};

export default Index;
