import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { combineClassName } from "src/utils/combineClassName";

import Burger from "src/components/Burger";

import "./Header.scss";

import image_logo from "src/assets/logo.png"

type NavLinkProps = {
    className?: string;
    // text: string;
    containerId?: string | undefined;
    linkTo: string;
    onClick?(): void;
    isIndex: boolean;
    children: React.ReactNode;
}

export const NavLink: React.FC<NavLinkProps> = (props) => {
    return props.isIndex
        ? <ScrollLinkItem {...props} />
        : <LinkItem {...props} />
}

const ScrollLinkItem: React.FC<NavLinkProps> = (props) => {
    return <ScrollLink
        className={combineClassName("link", props.className)}
        activeClass="active"
        to={props.linkTo}
        containerId={props.containerId}
        spy={true}
        smooth={true}
        duration={300}
        onClick={() => props.onClick && props.onClick()}
    >
        {props.children}
    </ScrollLink>
}
const LinkItem: React.FC<NavLinkProps> = (props) => {
    return <Link
        to={props.linkTo}
        className={combineClassName("link", props.className)}
    >
        {props.children}
    </Link>
}
// const LinkItem: React.FC<NavLinkProps> = (props) => {
//     const history = useHistory()
//     return <div
//         className={combineClassName("item item-link", props.className)}
//         onClick={e => {
//             history.push(`/#${props.linkTo}`)
//             props.onClick && props.onClick()
//         }}
//     >
//         {props.children}
//     </div>
// }


const Header: React.FC = () => {
    const { t } = useTranslation();

    // menu
    const [ isMenuOpen, setMenuOpen ] = useState(false);
    const toggleMenuOpen = () => setMenuOpen(!isMenuOpen);
    const openMenu = () => setMenuOpen(true);
    const closeMenu = () => setMenuOpen(false);

    // is index
    const [ isIndex, setIsIndex ] = useState(false);
    useEffect(() => {
        setIsIndex(location.pathname === "/");
    }, [ location, setIsIndex ]);

    return (
        <div className="header">

            <div className="nav-left">
                {/*<Burger*/}
                {/*    open={isMenuOpen}*/}
                {/*    onOpen={toggleMenuOpen}*/}
                {/*/>*/}
                <NavLink containerId="index" linkTo="section-home" isIndex={isIndex}>
                    <img className="logo" src={image_logo} alt="Logo"/>
                </NavLink>
            </div>

            <nav className="nav-right">
                <NavLink containerId="index" linkTo="section-about" isIndex={isIndex}>
                    {t("header.about")}
                </NavLink>
                {/*<NavLink containerId="index" linkTo="section-platform" isIndex={isIndex}>*/}
                {/*    {t("header.platform")}*/}
                {/*</NavLink>*/}
                <NavLink containerId="index" linkTo="section-plan" isIndex={isIndex}>
                    {t("header.plan")}
                </NavLink>
                <NavLink containerId="index" linkTo="section-contact" isIndex={isIndex}>
                    {t("header.contact")}
                </NavLink>
            </nav>

        </div>
    );
};

export default Header;
