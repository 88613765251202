import React from "react";
import { useTranslation } from "react-i18next";

import "./About.scss";

import background from "src/assets/plan.jpg";

const About: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="section-about" id="section-about">
            <div className="title">{t("index.about.title")}</div>
            <div className="grid">
                <div className="text-wrap">
                    <div className="text">{t("index.about.text")}</div>
                </div>
                <div className="image-wrap">
                    <img src={background} alt="Image"/>
                </div>
            </div>
        </div>
    );
};

export default About;
