import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/Header";
import Index from "./pages/Index";

import "./App.scss"

function App() {
    return (
        <Router>
            <Header/>
            <Routes>
                <Route path="/" element={<Index/>}/>
            </Routes>
        </Router>
    );
}

export default App;
