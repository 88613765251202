import React from "react";
import { combineClassName } from "src/utils/combineClassName";

import "./Page.scss"

interface Props {
    className?: string;
    id?: string;
    children: React.ReactNode;
}

const Page: React.FC<Props> = (props) => {
    return (
        <div className={"page"} id={props.id}>
            <div className={combineClassName("page-content", props.className)}>
                {props.children}
            </div>
        </div>
    )
}

export default Page;
