import React from "react";
import { useTranslation } from "react-i18next";

import "./Plan.scss";

import image_background from "src/assets/timeline.png"

const Plan: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className="section-plan" id="section-plan">
            <div className="content">
                <div className="title">{t("index.plan.title")}</div>
                <img className="image" src={image_background} alt="Image" />
            </div>
        </div>
    );
};

export default Plan;
