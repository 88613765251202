import React from "react";
import { useTranslation } from "react-i18next";

import "./Contact.scss";

import image_email from "src/assets/email.png"
import image_phone from "src/assets/phone.png"

const Contact: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className="section-contact" id="section-contact">
            <div className="section-wrapper">
                <div className="column-financing">
                    <div className="title">{t("index.contact.financing.title")}</div>
                    <div className="text">{t("index.contact.financing.text")}</div>
                </div>
                <div className="column-contact">
                    <div className="title">{t("index.contact.title")}</div>
                    <div className="name">{t("index.contact.name")}</div>
                    <div className="row-email">
                        <img className="image" src={image_email} alt="Icon"/>
                        <div className="text">{t("index.contact.email")}</div>
                    </div>
                    <div className="row-phone">
                        <img className="image" src={image_phone} alt="Icon"/>
                        <div className="text">{t("index.contact.phone")}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
