import React from "react";
import { useTranslation } from "react-i18next";

import "./Home.scss";

const Home: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className="section-home" id="section-home">
            <div className="top">
                <div className="background"/>
                <div className="overlay"/>
                <div className="content">
                    <div className="title">{t("index.home.title")}</div>
                </div>
            </div>
            <div className="divider"/>
        </div>
    );
};

export default Home;
