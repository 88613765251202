import React from "react";
import { useTranslation } from "react-i18next";

import "./Sponsors.scss";

import image_sponsor_1 from "src/assets/sponsor_1.png"
import image_sponsor_2 from "src/assets/sponsor_2.png"
import image_sponsor_3 from "src/assets/sponsor_3.png"
import image_sponsor_4 from "src/assets/sponsor_4.png"

const Sponsors: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className="section-sponsors" id="section-sponsors">
            <div className="content">
                <div className="title">{t("index.sponsors.title")}</div>
                <div className="images">
                    <img className="image" src={image_sponsor_1} alt="Image" />
                    <img className="image" src={image_sponsor_2} alt="Image" />
                    <img className="image" src={image_sponsor_3} alt="Image" />
                    <img className="image" src={image_sponsor_4} alt="Image" />
                </div>
            </div>
        </div>
    );};

export default Sponsors;
